/** * 合作案例 */
<template>
  <v-app class="section-box">
    <v-banner
        single-line
        height="250"
        class="banner"
      >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/h5-case-banner.png"
          height="100%"
          :transition="false"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1060 mt-10">
          <v-card color="transparent banner-text" flat>
            <v-card-title class="pa-0 font-size-32 family-Bold justify-center line-height-40 text-align-center font-size-banner-title">
              刷爆朋友圈 <br> 持续提升经营指标
            </v-card-title>
            <v-card-title class="pa-0 font-size-14 mt-7 justify-center font-size-banner-tip">
              获取流量   ｜   活跃用户   ｜   提升留存   ｜   提升品牌影响力
            </v-card-title>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>  
    
    <v-tabs centered color="#0084ff" class="g-tabs-h5" :show-arrows="false">
      <v-tab 
        v-for="(v, k) in data" 
        :key="k"
        @click="clickLevelOne(k)"
        >
        <b>{{ v.title }}</b>
      </v-tab>
    </v-tabs>

    <v-tabs
      centered
      fixed-tabs
      hide-slider
      class="new-tabs-h5"
      :show-arrows="false"
      v-model="tab"
    >
      <v-tab 
        :value="levelTwoIndex"
        v-for="(v,k) in data[levelOneIndex].tab"
        :key="k"
        @click="clickLevelTwo(k)"
      >{{ v.tabTilte }}</v-tab>
    </v-tabs>
  <div class="div-tabs">

    <v-tabs-items v-model="tab" class="new-tabs-items-h5" v-cloak>
      <v-tab-item
        v-model="tab"
        v-for="(v, k) in data[levelOneIndex].tab[levelTwoIndex].tabItem"
        :key="k"
      >
        <v-container fluid>
            <v-card
              class="mx-auto item-card"
              max-width="240"
              max-height="410"
              @click="goCaseDetail(levelOneIndex, levelTwoIndex, k)"
            >
              <v-img
                max-height="340"
                class="tran-sec"
                :src="v.coverImg"
                :aspect-ratio="9/16"
              >
                <div class="text-box">
                  <v-card-text class="title" v-text="v.itemTitle"></v-card-text>
                </div>
              </v-img>
            </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>

  </v-app>
</template>

<script>
import textData from '@/static/textData.js'
export default {
  name: 'CooperationCase',
  data() {
    return {
      data: textData.CooperationCase,
      levelOneIndex: 0,
      levelTwoIndex: 0,
      tab: null,
      imgLoad: false
    }
  },
  created() {
  },
  methods: {
    goCaseDetail(levelOneIndex, levelTwoIndex, vid) {
      this.$router.push({ 
        name: 'h5CaseDetail',
        query: {
          source: 'case',
          levelOneIndex: levelOneIndex,
          levelTwoIndex: levelTwoIndex,
          vid: vid,
        }
       })
      // window.open(url, '_blank')
    },
    clickLevelOne(k) {
      this.levelOneIndex = k
      this.levelTwoIndex = 0
      this.tab = 0
    },
    clickLevelTwo(k) {
      this.levelTwoIndex = k
    }
  }

}
</script>

<style lang="scss" scoped>
.section-box {
  background: #f5f8fd;
  position: relative;
  z-index: 6;

  // overflow-x: hidden;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  ::v-deep .v-responsive__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}


.div-tabs {
  // min-height: 520px !important;
  position: relative;
  z-index: 7;
}

</style>